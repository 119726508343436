import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AllService {

  constructor(private http: HttpClient) { }
  /** Registrar el usuario */
  register = (): Observable<any> => this.http.get(`https://metha.maisoncapilar.com/api/hair/register-client`);

  /** Obtiene todas las ciudades almacenadas en bd */
  getCiudades = (): Observable<any> => this.http.get(`https://metha.maisoncapilar.com/api/hair/get-ciudades`);

  /** Obtiene todos los salones almacenadas en bd */
  getAllRooms = (): Observable<any> => this.http.get(`https://metha.maisoncapilar.com/api/hair/getAllRooms`);

  /** Obtiene todos los cepillados almacenadas en bd */
  getAllCepillados = (): Observable<any> => this.http.get(`https://metha.maisoncapilar.com/api/hair/getAllCepillados`);

  /** Obtiene todas las aplicaciones almacenadas en bd */
  getAllAplications = (): Observable<any> => this.http.get(`https://metha.maisoncapilar.com/api/hair/getAllAplications`);

  /** Obtiene todas los tipos de cabellos almacenadas en bd */
  getAllCabellos = (): Observable<any> => this.http.get(`https://metha.maisoncapilar.com/api/hair/getAllTypes`);

  /** Guardar factura */
  saveFactura = (params): Observable<any> => this.http.post(`https://metha.maisoncapilar.com/api/hair/saveFactura`, params);

  /** Envía codigo por correo */
  sendEmailCode = (params): Observable<any> => this.http.post(`https://metha.maisoncapilar.com/api/hair/sendCode`, params);

  /** Obtiene todas las aplicaciones almacenadas en bd */
  confirmCode = (params): Observable<any> => this.http.post(`https://metha.maisoncapilar.com/api/hair/confirmCode`, params);

  /**Obtiene toda la información de la factura segun el codigo*/
  getFactura = (codigo):Observable<any>=>this.http.post(`https://metha.maisoncapilar.com/api/hair/getFactura`,codigo);
}
