import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllService } from '../../services/all.service';
import { SwalPopupService } from '../../services/LocalServices/swal-popup-local.service';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss']
})
export class ResponseComponent implements OnInit {
  codigo: string|number;
  text='Error';

  constructor(
    public allService: AllService,
    private _route: ActivatedRoute,
    private toast:SwalPopupService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.codigo = this._route.snapshot.paramMap.get('codigo');
    
    this.allService.getFactura({codigo:this.codigo}).subscribe(data=>{
      if (data.status === 1) {
        this.text=data.message;
        const params = {
          nombre:data.factura['nombre_usuario'],
          correo:data.factura['correo_usuario'],
          codigo:this.codigo,
          salon:data.factura['salon'],
          direccion_salon:data.factura['direccion_salon'],
          aplicacion:data.factura['aplicacion'],
          cepillado:data.factura['cepillado']
        };
        this.allService.sendEmailCode(params).subscribe(data => {
          if (data.status === 0) {
            this.toast.setToastPopup(data.message,'danger');    
          }else{ 
            setTimeout(() => {
              this.router.navigate(['/length']);
            }, 5000);
          }
        });
      }
    });

  }
  
}
