import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SystemService {

  /** Propiedades de la ventana */
  viewport: Record<'desktop' | 'fontSize' | 'tablet' | 'phone', number> = {
    desktop: Infinity,
    fontSize: 15,
    tablet: 960,
    phone: 640
  };

  /** Establece la ruta actual */
  routes: { current: BehaviorSubject<number>, real: BehaviorSubject<number> } = {
    current: new BehaviorSubject(0),
    real: new BehaviorSubject(0)
  };

  formatList: Record<'percentage' | 'currency' | 'number' | 'index', Intl.NumberFormatOptions> = {
    percentage: { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 0 },
    index: { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 0 },
    currency: { style: 'currency', currency: 'COP', currencyDisplay: 'symbol' },
    number: { style: 'decimal' }
  };

}
