import { Injectable } from '@angular/core';

import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class SwalPopupService {

  private colors = {
    success: '#2ecc71',
    danger: '#ff2c4b'
  }

  private toast = Swal.mixin({
    showConfirmButton: false,
    position: 'bottom-end',
    timerProgressBar: true,
    toast: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
      toast.addEventListener('click', Swal.clickCancel)
    }
  });

  constructor() { }

  public setToastPopup = (title, status: 'success' | 'danger', timer: number = 3000) => this.toast.fire({ title, background: this.colors[status], timer: timer })
}