import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfirmCodeComponent } from './components/ConfirmCode/confirm-code.component';
import { HairTypeComponent } from './components/hair-type/hair-type.component';
import { ResponseComponent } from './components/Response/response.component';


const routes: Routes = [
  { path: 'response/:codigo', component: ResponseComponent },
  { path: 'confirmCode', component: ConfirmCodeComponent },
  { path: 'length', component: HairTypeComponent },
  { path: '**', pathMatch: 'full', redirectTo: '/length' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
