<div class="page-content primary-typography" (window:resize)="onResize($event)">
    <ng-container *ngIf="selectedIndex !== 4">
        <div class="content-header">
            <div class="content-img-left">
                <img src="assets/images/fondo.png" />
            </div>
            <div class="content-logo">
                <img src="assets/images/logo.png" />
            </div>
        </div>
    </ng-container>
    <div class="content-body">
        <!-- seleccion cabello -->
        <ng-container *ngIf="selectedIndex === 0">
            <div class="tittle-hair" data-aos="fade-up" data-aos-duration="1200">
                <h2>Selecciona cual de los 6 tipos de cabellos es el tuyo</h2>
            </div>
            <div class="first-container" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                <div class="container-img-left-carousel" *ngIf="width > 500 ">
                    <section class="icons-directions" (click)="slidePrev()">
                        <span class="material-icons">navigate_before</span>
                    </section>
                </div>
                <swiper [breakpoints]="breakpoints" #swiper [navigation]="true">
                    <ng-container *ngFor="let cabello of cabellos">
                        <ng-template swiperSlide>
                            <div class="container-img-carousel cursor-pointer" (click)="nextIndex('next', cabello)">
                                <img data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
                                    src="assets/images/{{ cabello.img }}.jpg" />
                                <h1 class="
                                    cl-primary-background-palette-important
                                    text-align-center
                                    margin-bottom-0
                                    title-style" style="
                                    font-size: 2.8rem;
                                    letter-spacing: 3px;
                                    font-weight: 300;">
                                    {{ cabello.tipo | uppercase }}
                                </h1>
                                <p class="
                                    text-style text-align-center
                                    margin-bottom-0
                                    lower-font">
                                    {{ cabello.longitud | uppercase }}
                                </p>
                            </div>
                        </ng-template>
                    </ng-container>
                </swiper>
                <div class="container-img-right-carousel" *ngIf="width > 500">
                    <section class="icons-directions" (click)="slideNext()">
                        <span class="material-icons">chevron_right</span>
                    </section>
                </div>
            </div>
        </ng-container>
        <!-- verificacion de valor y producto -->
        <ng-container *ngIf="selectedIndex === 1">
            <div data-aos="fade-down" class="second-container">
                <div class="row">
                    <div class="col s12 m6">
                        <div class="display-flex justify-content-flex-end">
                            <img src="assets/images/{{ this.aplicacionSelected[0]?.img }}.jpg"
                                style="height: 60vh; width: 22rem" />
                        </div>
                    </div>
                    <div class="col s12 m6">
                        <div class="
                justify-content-center
                flex-direction-column
                display-flex
                scroll-box
              " style="height: 60vh" [ngStyle]="{'height': width<500?'40vh':'60vh','padding': width<500?'0.5rem':'0','justify-content':width<500?'start':'center'}">
                            <section>
                                <h1 class="
                    cl-primary-background-palette-important
                    margin-top-0
                    title-style
                  " style="
                    font-size: 3.266667rem;
                    letter-spacing: 3px;
                    font-weight: 300;
                  ">
                                    {{ this.productoFilter[0]?.tipo_cabello | uppercase }}
                                </h1>
                                <h4 class="title-style margin-vertical-0">
                                    {{ this.aplicacionSelected[0]?.longitud_cabello | uppercase }}
                                </h4>
                                <p class="text-style lower-font">
                                    El producto para tu aplicación tiene un valor de:
                                </p>
                                <h1 style="letter-spacing: 3px; font-weight: 300" class="title-style">
                                    {{ this.aplicacionSelected[0]?.valor_iva | currency }}
                                </h1>
                                <div class="container-info-service">
                                    <section>
                                        <img src="assets/images/PHONE1.png" class="phone" />
                                    </section>
                                    <section>
                                        <p class="text-style lowest margin-vertical-0">
                                            Para más opciones de aplicación contáctanos al 3153077159
                                        </p>
                                    </section>
                                </div>
                                <div class="btn-next" [ngStyle]="{'width': width >755? '60vh': 'auto'}">
                                    <button class="btn-green" (click)="nextIndex('next')">Seleccionar salon</button>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- seleccion salon -->
        <ng-container *ngIf="selectedIndex === 2">
            <div class="tertiary-container center-all flex-direction-column" data-aos="fade-left">
                <section>
                    <div class="container-img-left-tertiary">
                        <img src="assets/images/icon-izq.png" />
                    </div>
                    <article>
                        <p class=" text-align-center cl-primary-background-palette-important margin-vertical-0 higher-font title-style">
                            ¿En qué salón te es más
                            <b class="title-style higher-font margin-vertical-0 text-align-center">
                                cómoda la aplicación?
                            </b>
                        </p>
                    </article>
                    <div class="container-img-right-tertiary">
                        <img src="assets/images/icon-dere.png" />
                    </div>
                </section>
                <div class="info">
                    <select class="select-ciud" #ciu (change)="ciud = ciu.value;">
                        <option value="" selected>Seleccionar ciudad:</option>
                        <option *ngFor="let citie of cities" value="{{ citie.ciu_id }}">
                            {{ citie.ciu_nombre }}
                        </option>
                    </select>
                    <div class="container-cards" *ngIf="ciud !== ''" data-aos="fade-down">
                        <h3 class="tittle-salon">Selecciona tu salón a tú conveniencia.
                        </h3>
                        <div class="salon-container" data-aos="fade-up" data-aos-easing="linear"
                            data-aos-duration="1500">
                            <table class="table table-bordered table-responsive">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Nombre salón</th>
                                        <th>Zona</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let room of rooms; index as i">
                                        <ng-container *ngIf="+room.ciu_id === +ciud">
                                            <tr>
                                                <td>
                                                    <p class="mb-0 display-flex justify-content-center align-items-center" [ngStyle]="{'text-align': 'center'}" >
                                                        {{ ((room?.salon).split(' ').length > 2? (room?.salon).split('
                                                        ')[0]+' '+(room?.salon).split(' ')[1] : (room?.salon).split('
                                                        ')[0]) |uppercase }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p class="mb-0 display-flex justify-content-center align-items-center" class="text-style higher-font font-weight-700 margin-vertical-0 psubtitle" [ngStyle]="{'text-align': 'center'}" >
                                                    {{ ((room?.salon).split(' ').length > 2?
                                                        (room?.salon).split(' ')[2] :
                                                        (room?.salon).split(' ')[1]) |uppercase }}
                                                    </p>
                                                </td>
                                                <td class="display-flex justify-content-center align-items-center">
                                                    <p class="p-item"(click)="selectedRoom(room)" [modalStructure]="'#infoRoom'" [appModal] [ngStyle]="{'background': room.id_salon === roomSelected?.id_salon?'#94703f':'#94703f29'}">Ver más</p>
                                                </td>
                                            </tr>

                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="display-flex justify-content-center" *ngIf="width < 500 && selectedIndex === 1">

                            <section class="icons-directions" (click)="slidePrev()">
                                <span class="material-icons">navigate_before</span>
                            </section>
                            <section class="icons-directions" (click)="slideNext()">
                                <span class="material-icons">chevron_right</span>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- costos del producto -->
        <ng-container *ngIf="selectedIndex === 3">
            <div data-aos="zoom-in-down" class="third-container">
                <div class="quaternary-container center-all flex-direction-column">
                    <section class="margin-bottom-80-rem">
                        <div class="container-img-left-tertiary">
                            <img src="assets/images/icon-izq.png" />
                        </div>
                        <article>
                            <span class="
                cl-primary-background-palette-important
                margin-right-50-rem margin-vertical-0
                higher-font
                title-style
              ">
                                El precio de esta compra, incluido IVA,
                            </span>
                            <span class="title-style higher-font margin-vertical-0">
                                por tu producto es de
                            </span>
                        </article>
                        <div class="container-img-right-tertiary">
                            <img src="assets/images/icon-dere.png" />
                        </div>
                    </section>
                    <section class="margin-top-80-rem">
                        <h1 style="letter-spacing: 3px; font-weight: 300"
                            class="title-style margin-vertical-0 text-align-center">
                            {{ this.aplicacionesFilter[0]?.valor_iva | currency }}
                            <p class="text-style text-align-center" style="letter-spacing: 3px; font-weight: 300">
                                Este es el cobro por el producto paso #1 y paso #2
                            </p>
                        </h1>
                        <hr class="primary-separator stronger-border width-100 margin-top-0" />
                    </section>
                    <h2>
                        <p class="text-style higher-font margin-top-2 text-align-center">
                            En el salón de belleza que tu escogiste, pagaras tu aplicación y cepillado con los valores
                            que ellos nos dieron. Para salones ubicados en Miami el valor esta en dolares USD.
                        </p>
                    </h2>

                    <!-- Sector Aplicacion -->
                    <section class="margin-bottom-150-rem">
                        <h5 style="letter-spacing: 3px; font-weight: 300"
                            class="title-style margin-vertical-0 text-align-center">
                            {{ this.aplicacionesFilter[0]?.valor | currency }}
                        </h5>
                        <p class="text-style higher-font margin-top-0 text-align-center">
                            APLICACIÓN
                        </p>
                        <!-- <label class="primary-form-switch">
              <input
                [attr.data-checked]="statusSelectedService.aplicacion"
                (change)="sumValues('aplicacion')"
                class="primary-switch"
                type="checkbox"
              />
              <span class="slider round"></span>
            </label> -->
                    </section>
                    <!-- Sector Cepillado -->
                    <section class="margin-bottom-150-rem">
                        <h5 style="letter-spacing: 3px; font-weight: 300"
                            class="title-style margin-vertical-0 text-align-center">
                            {{ cepilladosFilter[0]?.valor | currency }}
                        </h5>
                        <article class="display-flex justify-content-center">
                            <p class="text-style higher-font margin-vertical-0 text-align-center">
                                CEPILLADO
                            </p>
                            <!-- <label class="primary-form-switch">
              <input
                [attr.data-checked]="statusSelectedService.cepillado"
                (change)="sumValues('cepillado')"
                class="primary-switch"
                type="checkbox"
              />
              <span class="slider round"></span>
            </label> -->
                        </article>
                    </section>
                    <section>
                        <div class="btn-next">
                            <button class="btn-green" (click)="nextIndex('next')">Iniciar compra</button>
                        </div>
                    <!-- <h1
                        style="letter-spacing: 3px"
                        class="title-style margin-vertical-0 text-align-center"
                    >
                        {{ total | currency }}
                    </h1>
                    <p class="text-style higher-font margin-vertical-0 text-align-center">
                        TOTAL
                    </p> -->
                    </section>
                </div>
            </div>
        </ng-container>
        <!-- formulario de confirmacion -->
        <ng-container *ngIf="selectedIndex === 4">
            <div data-aos="fade-left" class="four-container">
                <form target="_blank" ngNoForm class="quinary-container"
                    action="https://checkout.payulatam.com/ppp-web-gateway-payu/" name="payuform" method="POST">
                    <div class="row">
                        <div class="col s12 l6">
                            <div class="shoping-card">
                                <div class="card-header">
                                    <img src="assets/images/product.png" alt="" />
                                </div>
                                <div class="card-body" data-aos="flip-left">
                                    <h3 style="font-weight: 300" class="title-style text-align-center margin-0">
                                        VALIDA TUS DATOS
                                    </h3>
                                    <div class="row">
                                        <div class="container">
                                            <!-- Nombre -->
                                            <div class="content-input width-full">
                                                <i class="material-icons">person</i>
                                                <input class="input full-width" name="buyerFullName"
                                                    placeholder="Nombres y apellidos" type="text" required #nombre />
                                            </div>
                                            <!-- Telefono -->
                                            <div class="content-input">
                                                <i class="material-icons">phone_enabled</i>
                                                <input class="input full-width" type="text" placeholder="Numero celular"
                                                    required #celular />
                                            </div>
                                            <!-- Correo -->
                                            <div class="content-input">
                                                <i class="material-icons">mail</i>
                                                <input name="buyerEmail" type="text" #correo
                                                    placeholder="Correo electronico" class="input full-width"
                                                    required />
                                            </div>
                                            <!-- Ciudad -->
                                            <div class="content-input">
                                                <i class="material-icons">place</i>
                                                <input class="input full-width" type="text" placeholder="Ciudad"
                                                    #ciudad />
                                            </div>
                                            <!-- tipo de documento -->
                                            <div class="content-input">
                                                <i class="material-icons">assignment_ind</i>
                                                <select class="input full-width" required #tipo>
                                                    <option value="">Tipo de documento:</option>
                                                    <option value="TI">Tarjeta de identidad</option>
                                                    <option value="CC">Cédula de ciudadania</option>
                                                    <option value="CE">Cédula de extranjeria</option>
                                                </select>
                                            </div>
                                            <!-- numero de documento -->
                                            <div class="content-input">
                                                <i class="material-icons">assignment_ind</i>
                                                <input class="input full-width" type="text"
                                                    placeholder="Numero de documento" required #documento />
                                            </div>
                                            <!--referido por-->
                                            
                                                <div class="content-input" data-aos="fade-down">
                                                    <i class="material-icons">person</i>
                                                    <input class="input full-width" type="text" #referido
                                                        placeholder="Referido por" />
                                                </div>
                                            <!-- Deseas dar un regalo -->
                                            <div class="content-input">
                                                <!-- <label class="primary-form-switch"> -->
                                                <input [attr.data-checked]="statusSelectedService.regalo"
                                                    (change)="sumValues('regalo')" type="checkbox" />
                                                <span class="slider round"></span>¿Desea darlo como regalo?
                                            </div>
                                            <!-- persona regalo -->
                                            <!-- <div class="content-input" [ngStyle]="{'display': statusSelectedService.regalo ? 'block':none}" data-aos="fade-down"> -->
                                            <div class="content-input" *ngIf="statusSelectedService.regalo" data-aos="fade-down">
                                            <i class="material-icons">person</i>
                                            <input class="input full-width" type="text" #destinatario
                                                placeholder="Nombres y apellidos" />
                                        </div>
                                        <br />
                                        <!-- boton -->
                                        <div class="content-input">
                                            <input [attr.data-checked]="statusSelectedService.condiciones"
                                                [modalStructure]="'#condiciones'" [appModal]
                                                (change)="sumValues('condiciones')" type="checkbox" />
                                            <span class="slider round">Acepto términos y condicones</span>

                                        </div>
                                        <div class="text-align-center" *ngIf="statusSelectedService.condiciones">
                                            <button class="primary-button" style="background-color: #3a270c;"
                                                type="submit"
                                                (click)="sendFactura(nombre.value,celular.value,correo.value,ciudad.value,tipo.value,documento.value,destinatario?.value)">
                                                <div class="center-all">
                                                    <span class="material-icons-outlined margin-right-100-rem">
                                                        shopping_cart
                                                    </span>
                                                    Comprar
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <input name="merchantId" type="hidden" value="929602" />
                                <input name="accountId" type="hidden" value="936913" />
                                <input name="description" type="hidden" value="Compra producto online" />
                                <input name="referenceCode" type="hidden" [value]="codigo" />
                                <input name="amount" type="hidden" [value]="total" />
                                <input name="tax" type="hidden" value="0" />
                                <input name="taxReturnBase" type="hidden" value="0" />
                                <input name="currency" type="hidden" value="COP" />
                                <input name="signature" type="hidden" [value]="signature" />
                                <input name="test" type="hidden" value="0" />
                                <input name="responseUrl" type="hidden"
                                    value="https://metamorphosis.maisoncapilar.com/response/{{codigo}}" />
                                <input name="confirmationUrl" type="hidden"
                                    value="https://metamorphosis.maisoncapilar.com/response/{{codigo}}" />
                            </div>
                        </div>
                    </div>
            </div>
            </form>
    </div>
    </ng-container>

</div>
<!-- menu pie de pagina -->
<div class="content-footer" [ngStyle]="{'padding-bottom': (width < 500 && selectedIndex === 0)? ' 10rem': '1rem'}">
    <section class="icons-directions" (click)="slidePrev()" *ngIf="width < 500 && selectedIndex === 0">
        <span class="material-icons">navigate_before</span>
    </section>
    <section class="icons-directions" (click)="slideNext()" *ngIf="width < 500 && selectedIndex === 0">
        <span class="material-icons">chevron_right</span>
    </section>
    <section class="icon-footer" (click)="nextIndex('back')" *ngIf="selectedIndex > 0">
        <span class="material-icons">arrow_back</span>
    </section>
    <section class="icon-footer" (click)="nextIndex('home')" *ngIf="selectedIndex > 0">
        <span class="material-icons">home</span>
    </section>
    <!-- <section class="icon-footer" (click)="nextIndex('next')" *ngIf="selectedIndex > 0 && selectedIndex < 4">
        <span class="material-icons">arrow_forward</span>
    </section> -->
</div>
</div>
<div class="modal-surface primary-typography" id="infoRoom" #myModal>
    <div class="modal-content">
        <div class="primary-modal" style="width: 100vh">
            <div class="modal-header-style">
                Información salón
                <!-- <i class="modal-close-icon-style fas fa-times" template-name="modal-close"></i> -->
                <i class="modal-close-icon-style fas fa-times" template-name="modal-close" (click)="closeModel()"></i>
            </div>
            <div class="modal-body-style scroll-box">
                <ng-container *ngIf="+roomSelected?.disable === 0">
                    <article>
                        <p class="text-style higher-font margin-vertical-0">
                            En este momento, este salón de belleza esta en proceso de sistematización. Pero no dejes de
                            comprar, si esté salón es el que prefieres, continua con tu compra, que en el cupón que te
                            daremos cuando finalices la compra, encontraras el número para agendar
                            tu cita en el salón seleccionado. Gracias
                        </p>
                    </article>
                </ng-container>
                <ng-container>
                    <div class="row" style="margin-top: 2%;">
                        <div class="col s6 margin-bottom-100-rem" >
                            <p class="text-style highest-font font-weight-700 margin-vertical-0">
                                Nombre
                            </p>
                            <p class="text-style higher-font margin-vertical-0">
                                {{ roomSelected?.salon }}
                            </p>
                        </div>
                        <div class="col s6 margin-bottom-100-rem">
                            <p class="text-style highest-font font-weight-700 margin-vertical-0">
                                Zona
                            </p>
                            <p class="text-style higher-font margin-vertical-0">
                                {{ roomSelected?.direccion }}
                            </p>
                        </div>
                        <div class="col s6 margin-bottom-100-rem">
                            <p class="text-style highest-font font-weight-700 margin-vertical-0">
                                Red
                            </p>
                            <p class="text-style higher-font margin-vertical-0">
                                {{ roomSelected?.redes }}
                            </p>
                        </div>
                        <div class="col s6 margin-bottom-100-rem">
                            <div class="btn-next" [ngStyle]="{'justify-content':'start'}">
                                <button class="btn-green" (click)="nextIndex('next')">Resumen de compra</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="modal-surface primary-typography" id="condiciones">
    <div class="modal-content">
        <div class="primary-modal" style="width: 100vh">
            <div class="modal-header-style">
                TÉRMINOS Y CONDICIONES
                <i class="modal-close-icon-style fas fa-times" template-name="modal-close"></i>
            </div>
            <div class="modal-body-style scroll-box">
                <p>
                    <b>TERMINOS Y CONDICIONES PROMOCIÓN METAMORPHOSIS MAISON CAPILAR S.A.S. <br> - VIGENCIA DE LA
                    PROMOCIÓN:</b>del 01 de septiembre de 2022 al 31 de diciembre de 2022
                    - Un ciclo de Maison Metamorphosis completo consta de 3 aplicaciones (día 0, día 10 y día 20). Con esta
                    compra estas adquiriendo el producto solamente para la primera aplicación (día cero), que te permite comprobar
                    la eficacia en el resultado de la recuperación capilar. Cada cabello es un caso diferente en su diagnóstico, pero
                    en esta primera aplicación, después de 30 minutos, conseguirás entre un 60% y un 80% de restauración,
                    dependiendo del grado de agresión que tenga tu cabello. Normalmente en la segunda aplicación ya puedes
                    retomar los procesos de color, dependiendo del nuevo diagnóstico, sin embargo, por extremo que sea tu caso, al
                    terminar el ciclo completo ya puedes con certeza someter tu cabello a nuevos procesos de embellecimiento que
                    contengan algún tipo de agresión química.<br>
                    - Los BENEFICIOS obtenidos al realizar esta compra constan de: 1) ya no tendrás que comprar la unidad
                    mínima de 120ml, solo debes comprar la cantidad necesaria para la primera aplicación (menor inversión), de
                    acuerdo a las condiciones de tu cabello y que hayas seleccionado en la app de la página web. 2) obtienes el 20%
                    de descuento por unidad de mililitro (ml) con respecto al valor de comprarlo en el kit de 120ml. (Descuento ya
                    aplicado en el precio que se te muestra en la app de la página web). 3) obtendrás un 10% de descuento en los
                    precios de lista de los servicios contratados en el salón y que te salen en el cupón de compra. Para obtener este
                    descuento debes presentar en el salón seleccionado el cupón de compra del producto.
                    <br><b>CONDICIONES DE USO:</b> Una vez realices la compra por la app de la página web, recibirás al correo
                    electrónico que hayas registrado, el cupón de compra. Además debes tener en cuenta lo siguiente:<br>
                    1. La aplicación del producto, solo será realizada en un salón por un profesional de la belleza debidamente
                    capacitado y que adicionalmente diagnosticara tu caso.<br>
                    2. Su aplicación debe agendarse máximo dentro de los 30 días siguientes a la compra.<br>
                    3. El centro de atención de Maison Capilar S.A.S entrara en contacto contigo para el agendamiento, en las
                    siguientes 6 horas y hasta un máximo de 24 horas después de la compra. En caso que no contestes o no
                    logremos contactarte, te enviaremos un correo electrónico a la dirección inscrita al momento de la compra.<br>
                    4. El profesional que te atenderá, debe realizar primero un diagnóstico capilar, en el cual se confirmará la
                    cantidad de producto a utilizar. En caso de requerirse más producto, para el paso #1 el valor del mililitro (ml) a
                    pagar en el salón será de $1.171/ml (+ IVA) y para el paso #2 el valor del mililitro (ml) a pagar en el salón será
                    de $242/ml (+ IVA), el cual es el mismo que pagaste en la página. Por ello es importante que selecciones
                    adecuadamente tu tipo de cabello y así coincidir con el diagnóstico.<br>
                    5. En caso que, en el momento del diagnóstico, no coincida la información que hayas reportado de tu
                    cabello al momento de realizar la compra con el resultado del diagnóstico y no se llegue a un acuerdo respecto a la
                    cantidad del producto a aplicar, tanto el salón como tú, pueden decidir no realizar el servicio en ese salón y tú
                    puedes contactar nuevamente al número de atención de Maison Capilar S.A.S a reportar el caso y agendar una
                    nueva cita en otro salón.<br>
                    6. El Producto se entregará en el salón de belleza que hayas escogido. No se te entregara producto físico,
                    necesariamente tendrás que aplicarte el producto en el salón de belleza seleccionado; al realizar la compra estas
                    aceptando esto. Los salones no entregan el producto sin aplicar.<br>
                    7. Ten en cuenta que la persona que podrá hacer uso del cupón de compra es aquella a quien pusiste como
                    BENEFICIARIO DE LA COMPRA.<br>
                    8. Si al momento de ser atendida en el salón, decides hacerte la aplicación en otro salón o incluso antes de
                    desplazarte deseas hacer el cambio de tu cita, debes necesariamente llamar a la línea de contacto Maison 3153077159 y re agendar nuevamente. Es importante que tengas en cuenta que los cambios de citas están
                    sujetos a disponibilidad de los salones de belleza.
                    <br> <b> SUGERENCIAS POST
                    APLICACIÓN:</b> 1) Utilizar los productos de post cuidado de nuestras líneas
                    NutriMilk y Luminox, compatibles con los de Maison Metamorphosis, según la recomendación profesional del
                    estilista que te vaya a realizar la aplicación. 2) Si el diagnostico te permite hacerte color el mismo día de la
                    restauración capilar (para cualquier tono de color), te recomendamos aplicar la Maison Metamorphosis Paso 1
                    después del decolorante, posteriormente realizar el tratamiento de color y finalmente hacer terminación con la
                    Maison Metamorphosis Paso 2. 3) Debes tener en cuenta que, si tu cabello es de un color cenizo o platinado, en
                    alturas 9, 10 o 12, puede requerir un lavado matizante para corregir un desvío de un medio tono a cálido que
                    pueda llegar a suceder al usar Maison Metamorphosis.
                    <br><b> SERVICIOS Y/O PRODUCTOS NO INCLUIDOS EN LA COMPRA:</b> El precio del producto comprado en
                    porciones, NO INCLUYE, el costo de la aplicación del mismo, ni el cepillado de tu cabello en el salón
                    seleccionado. Los valores mencionados en el cupón para estos servicios corresponden a la tabla de precios
                    pasada por el salón a Maison y que el salón está comprometido a respetar para la selección de cabello que
                    escogiste al momento de la compra, y que el mismo salón comparara con el del diagnóstico capilar que te
                    realicen.<br>
                    <b>RESPONSABILIDADES:</b>1. Una vez iniciada la aplicación en el salón de tu elección aceptas tu conformidad
                    y confianza en el salón seleccionado. Y liberas a Maison Capilar de los resultados obtenidos, a menos que el
                    producto presente alguna falla inherente al material únicamente. 2. Es tu responsabilidad y la del salón verificar
                    la cantidad de mililitros (ml) que te van a aplicar antes de iniciar la aplicación. 3. Verificar que el producto que
                    te estén aplicando si sea Maison Metamorphosis y que lo usen del empaque original. No aceptes imitaciones. 4.
                    Después de la aplicación del producto y ver los resultados inmediatos en tu cabello, recuerda dejar tu
                    comentario del producto Maison Metamorphosis y el salón que te atendió, en la aplicación; en la sección de
                    “Escoge tu salón”.<br>
                    <b>DERECHO DE RETRACTO:</b>
                    Conforme lo señala el artículo 47 de la ley 1480 de 2011, puedes ejercer tu
                    derecho al retracto, dentro de los 5 días hábiles siguientes a la compra.
                    PARÁGRAFO: Para ejercer tu derecho al retracto deberá comunicarte con la línea de contacto Maison y hacer
                    la solicitud y se te devolverá tu dinero dentro de los 30 días siguientes a tu solicitud.<br>
                    <b> AUTORIZACIÓN DATOS PERSONALES (LEY 1581 DE 2012)</b>
                    De acuerdo con lo estipulado en la ley 1581 de 2012 y el decreto reglamentario 1377 de 2013, con la firma
                    electrónica del presente documento autorizo de manera libre, espontánea y expresa a MAISON CAPILAR
                    S.A.S., identificada con el Nit. 901.447.151-1, con domicilio principal en el municipio de Medellín, para
                    recolectar, transferir, almacenar, usar, circular, suprimir, compartir, actualizar y transmitir mis datos personales
                    y corporativos para los fines establecidos en la Política de tratamiento de datos personales.
                </p>
            </div>
        </div>
    </div>
</div>