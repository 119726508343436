// tslint:disable: no-unused-expression
// tslint:disable: max-line-length
import { Component, HostListener } from '@angular/core';
import { Router as R } from '@angular/router';

import { BehaviorOptions, IntersectionTypeList, TemplateOperators } from './classes/template.class';
import { Modal, ModalProperties } from './classes/modal.class';
import { Tooltip } from './classes/tooltip.class';

import { SystemService } from './services/TemplateServices/system.service';

import { ModalBehaviorProperties, TooltipBehaviorOptions, TooltipBehaviorProperties } from './interfaces/template-behavior.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /** Actualiza la fuente */
  @HostListener('window:resize') onResize(): void { this.setViewportFontSize(); }

  constructor(
    private system: SystemService,
    private router: R,
  ) {
    this.setViewportFontSize();

    this.setTooltipBehavior();
    this.setModalBehavior();

    // AOS.init({ duration: 1000 });
  }

  setViewportFontSize = () => {
    /** Listado de resoluciones, con sus propiedades y relaciones */
    const measures: any = {
      '8k': { height: 4320, width: 7680 },
      '4k': { height: 2160, width: 3840 },
      '2k': { height: 1080, width: 1920 },
      desktop: { height: 540, width: 960 },
      tablet: { height: 360, width: 640, reference: 'desktop' },
      phone: { height: 0, width: 0, reference: 'desktop' },
    };
    const defaultDevice = 'desktop';
    let referenceMeasure: number;
    let currentMeasure = 15;
    let reference: any;
    let device: string;
    let measure = 15;
    if (screen.width >= screen.height) {
      /** Tipo de dispositivo de acuerdo a la resolución actual  */
      device = Object.keys(measures).filter((elm: string) =>
        ((measures[elm].width - 1) / screen.width) < 1).reduce((pre: string, cur: string) =>
          (measures[pre].width > measures[cur].width ? pre : cur));
      currentMeasure = Math.sqrt(Math.pow(screen.height, 2) + Math.pow(screen.width, 2)) * 0.01;
      /** Resolución de referencia para calcular fuente */
      if ('reference' in measures[device]) {
        // reference = measures[measures[device].reference];
        reference = { height: measures[measures[device].reference].height, width: measures[measures[device].reference].width };
        referenceMeasure = Math.sqrt(Math.pow(reference.width, 2) + Math.pow(reference.height, 2)) * 0.01;
        currentMeasure = Math.sqrt(Math.pow(currentMeasure, 2) + Math.pow(referenceMeasure, 2));
        measure = currentMeasure;
      } else if (device !== defaultDevice) {
        reference = { height: measures[device].height - measures[defaultDevice].height, width: measures[device].width - measures[defaultDevice].width };
        referenceMeasure = Math.sqrt(Math.pow(reference.width, 2) + Math.pow(reference.height, 2)) * 0.01;
        measure = Math.sqrt(Math.pow(currentMeasure, 2) + Math.pow(referenceMeasure, 2));
      } else {
        reference = undefined;
        measure = currentMeasure;
      }
    }
    /** Modifica los estilos */
    this.system.viewport.fontSize = measure;
    let styleElement: HTMLStyleElement = document.querySelector('#style-element');
    styleElement ? styleElement.remove() : null; styleElement = document.createElement('style');
    styleElement.innerHTML = `:root { --diagonal: ${measure}px; --font: ${currentMeasure}px; --gutter: ${currentMeasure * 0.5}px; --negativeGutter: ${currentMeasure * -0.5}px; }`;
    styleElement.setAttribute('negative-gutter', `${currentMeasure * -0.5}`);
    styleElement.setAttribute('gutter', `${currentMeasure * 0.5}`);
    styleElement.setAttribute('font', `${currentMeasure}`);
    styleElement.setAttribute('diagonal', `${measure}`);
    styleElement.id = 'style-element';
    document.head.append(styleElement);
  }

  /** Implementa el comportamiento de tooltip */
  setTooltipBehavior(): void {
    class TooltipBehavior implements TooltipBehaviorProperties {
      tooltipInstance: Tooltip;

      constructor(public template: IntersectionTypeList['template'], public options: TooltipBehaviorOptions) { this.init(); }

      init() { this.tooltipInstance = new Tooltip(this.template.target, this.template.componentAttribute, this.options); }

      destroy = (): Promise<void> => new Promise(resolve => (this.tooltipInstance.destroy(), resolve()));
    }
    BehaviorOptions.use('tooltip', TooltipBehavior);
  }

  /** Implementa el comportamiento de ventana emergente */
  setModalBehavior() {
    class ModalBehavior implements ModalBehaviorProperties {
      modalInstance: Modal;

      constructor(public template: IntersectionTypeList['template'], public options: ModalProperties) { this.init(); }

      init() {
        if (this.template.target instanceof HTMLElement) {
          const clickEvent: Exclude<ModalProperties['clickEvent'], string> = typeof this.options.clickEvent === 'string' ? this.template.callback[this.options.clickEvent] : (typeof this.options.clickEvent === 'function' ? this.options.clickEvent : undefined);
          this.modalInstance = new Modal(this.template.target, { ...{ componentAttribute: this.template.componentAttribute }, ...this.options }, clickEvent);
          this.options.modalContext !== undefined && (this.modalInstance.modalContext = this.options.modalContext);
          this.options.headerContext !== undefined && (this.modalInstance.headerContext = this.options.headerContext);
          this.options.bodyContext !== undefined && (this.modalInstance.bodyContext = this.options.bodyContext);
        }
      }
      destroy = (): Promise<void> => new Promise(resolve => (this.modalInstance instanceof Modal && this.modalInstance.destroy(), resolve()));
    }
    BehaviorOptions.use('modal', ModalBehavior);
  }
}
