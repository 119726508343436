import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TooltipDirective } from './directives/tooltip.directive';
import { ModalDirective } from './directives/modal.directive';

import { ConfirmCodeComponent } from './components/ConfirmCode/confirm-code.component';
import { HairTypeComponent } from './components/hair-type/hair-type.component';
import { ResponseComponent } from './components/Response/response.component';

import { AppComponent } from './app.component';

import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SwiperModule } from 'swiper/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const DIRECTIVES = [
  TooltipDirective,
  ModalDirective,
];

@NgModule({
  declarations: [
    HairTypeComponent,
    AppComponent,
    ...DIRECTIVES,
    ResponseComponent,
    ConfirmCodeComponent
  ],
  imports: [
    ReactiveFormsModule,
    IvyCarouselModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    SwiperModule, 
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
