import { Component, OnInit } from '@angular/core';
import { AllService } from '../../services/all.service';

import { SwalPopupService } from '../../services/LocalServices/swal-popup-local.service';

@Component({
  selector: 'app-confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.scss']
})
export class ConfirmCodeComponent implements OnInit {

  constructor(
    public AllService:AllService,
    private toast:SwalPopupService
  ) { }

  ngOnInit(): void {
  }
  validar = (code) => {
    this.AllService.confirmCode({'codigo': code}).subscribe(data => {
      if (data.status === 1) {
        this.toast.setToastPopup(data.message,'success');
        setTimeout(() => {
          window.location.reload();
        }, 
        3000);
      }else if(data.status === 0){
        this.toast.setToastPopup(data.message,'danger');
      }
    })
  }
}
