<div class="page-content primary-typography center-all">
    <div class="primary-card">
        <h1 class="title-style">Confirmar factura</h1>
        <p class="text-style lower-font">
            Para confirmar la factura debes ingresar el código que te enviamos al correo
        </p>
        <label class="primary-form-field margin-bottom-100-rem">
      <input type="text" class="primary-input" #code />
    </label>
        <button class="primary-button width-100" (click)="validar(code.value)">Validar</button>
    </div>
</div>