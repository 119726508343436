import { Component, OnInit, ViewChild } from '@angular/core';

import { SwalPopupService } from '../../services/LocalServices/swal-popup-local.service';
import { AllService } from '../../services/all.service';

import { SwiperComponent } from 'swiper/angular';
import { Md5 } from 'ts-md5/dist/md5';
import AOS from 'aos';

@Component({
  selector: 'app-hair-type',
  templateUrl: './hair-type.component.html',
  styleUrls: ['./hair-type.component.scss'],
})
export class HairTypeComponent implements OnInit {

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  
  @ViewChild('myModal') myModal;
  /** Configuración slider */
  public breakpoints = {
    360: { slidesPerView: 1, spaceBetween: 20 },
    640: { slidesPerView: 2, spaceBetween: 20 },
    768: { slidesPerView: 2, spaceBetween: 20 },
    1024: { slidesPerView: 3, spaceBetween: 30 }
  };

  public breakpointsRoom = {
    360: { slidesPerView: 1, spaceBetween: 20 },
    640: { slidesPerView: 1, spaceBetween: 20 },
    768: { slidesPerView: 2, spaceBetween: 20 },
    1024: { slidesPerView: 2, spaceBetween: 30 }
  };

  public selectedIndex = 0;
  public cities;

  public statusSelectedService = {
    condiciones:false,
    regalo: false,
    city: false
  };

  public Salon:any;
  public aplicaciones = [];
  public cepillados = [];
  public cabellos = [];
  public item = false;
  public rooms = [];
  public ciud = '';

  public aplicacionesFilter = [];
  public cepilladosFilter = [];
  public productoFilter = [];

  public aplicacionSelected: any = {};
  public cepilladoSelected: any = {};
  public roomSelected: any = {};
  public hairSelected: any = {};

  public width = window.innerWidth;
  public signature = undefined;
  public codigo = undefined;
  public params = '';
  public total = 0;

  constructor(
    public allService: AllService,
    private toast:SwalPopupService

  ) {}

  ngOnInit(): void {
    this.getCiud();
    this.getAllRooms();
    this.getAllAplications();
    this.getAllCepillados();
    this.getAllCabellos();
    AOS.init();

    this.codigo = this.getRandomString(8);
  }
  closeModel(){
    this.myModal.nativeElement.style.display = 'none';
  }
  onResize(event) {
    this.width = event.target.innerWidth;
  }
  selectedRoom = (room: any) => {
    this.roomSelected = room;
    this.myModal.nativeElement.style.display = 'block';
    this.aplicacionesFilter = this.aplicacionSelected.filter(rooms => rooms.id_salon === this.roomSelected.id_salon);
    this.cepilladosFilter = this.cepilladoSelected.filter(rooms => rooms.id_salon === this.roomSelected.id_salon);
  }
  productSelect =(room: any)=> {
    this.productoFilter = this.aplicacionSelected.filter(hair => hair.id_cabello === this.hairSelected.id_hair);
  }

  nextIndex(option, type: any = '', service: any = '') {
    
    if (this.selectedIndex >= 0 && option === 'next') {
        if (this.selectedIndex === 2) {
          if(this.roomSelected.id_salon){
            this.Salon = this.rooms.filter(rooms => rooms.id_salon === this.roomSelected.id_salon);
            this.selectedIndex++;  
          }
        }else{
          this.roomSelected = {};
          this.ciud = '';
          this.selectedIndex++;
        }
    } else if (this.selectedIndex > 0 && option === 'back') {
        this.roomSelected = {};
        this.ciud = '';
        this.selectedIndex--;
    } else if (option === 'home') {
        this.roomSelected = {};
        this.ciud = '';
        this.selectedIndex = 0;
    }
   
    if (this.selectedIndex === 3) {
      this.closeModel();
      this.total = this.aplicacionesFilter[0]?.valor_iva;
    }

    if (this.selectedIndex === 4) {
      const md5 = new Md5();
      this.signature = md5.appendStr(`QzqrTh4ztYys1k3X384h46OIWq~929602~${this.codigo}~${this.total}~COP`).end();
    }

    if (type) {
      this.aplicacionSelected = this.aplicaciones.filter(tipo=> tipo.tipo_cabello === type.tipo && tipo.longitud_cabello ===type.longitud);
      this.cepilladoSelected = this.cepillados.filter(cepillado => cepillado.tipo_cabello === type.tipo && cepillado.longitud_cabello === type.longitud);
    }
  }

  getAllRooms = () => this.allService.getAllRooms().subscribe((response: any[]) => {
    this.rooms = response;
  })

  getAllCepillados = () => this.allService.getAllCepillados().subscribe((response: any[]) => {
    this.cepillados = response;
  })

  getAllCabellos = () => this.allService.getAllCabellos().subscribe((response: any[]) => {
    this.cabellos = response;
  })

  getAllAplications = () => this.allService.getAllAplications().subscribe((response: any[]) => {
    this.aplicaciones = response;
  })

  getCiud = () => {
    this.allService.getCiudades().subscribe(data => this.cities = data);
  }

  sumValues = (field: string) => this.statusSelectedService[field] = !this.statusSelectedService[field];

  getRandomString = (length) => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  sendFactura(nombre,celular,correo,ciudad,tipo,documento,destinatario?, referido?){
    
    if(nombre && celular && correo && ciudad && documento&& referido ){
      const params={
        nombre_usuario:nombre,
        telefono_usuario:celular,
        correo_usuario:correo,
        ciudad_usuario:ciudad,
        tipo_documento_usuario:tipo,
        numero_documento_usuario:documento,
        referido_por:referido?referido:'',
        destinatario:destinatario?destinatario:'',
        codigo:this.codigo,
        aplicacion:this.aplicacionesFilter[0]?.valor,
        cepillado:this.cepilladosFilter[0]?.valor,
        salon:this.Salon[0].salon,
        direccion_salon:this.Salon[0].direccion,
      }
      
      this.allService.saveFactura(params).subscribe(data=>{
        if(data.status === 0){ 
          this.toast.setToastPopup('Error','danger'); 
        }else{ 
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });
    }    
  }
  slideNext(){
    this.item = true;
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev(){
    this.swiper.swiperRef.slidePrev(100);
  }
}
