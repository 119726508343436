import { Injectable } from '@angular/core';

import { SystemService } from './system.service';

// import { SuffixOptions } from 'src';

@Injectable({ providedIn: 'root' })
export class HelperService {

    months: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    constructor(
        private system: SystemService
    ) { }

    /**
     * Convierte un valor definido en pixeles a su equivalente rem de acuerdo a la resolución del dispositivo
     * @param px Valor en pixeles
     * @param symbol Define si se agregará la medida
     * @param base Base general de 1rem
     */
    pxToRem = (px: number, symbol: ('rem' | '') = 'rem', base = 15): string => `${base ? (px / base) : 0}${symbol}`;

    /**
     * Convierte un valor definido en rem a su equivalente en pixeles de acuerdo a la resolución del dispositivo
     * @param rem Valor en rem
     * @param symbol Define si se agregará la medida
     * @param base Base definida por el sistema de 1rem
     */
    remToPx = (rem: number, symbol: ('px' | '') = '', base = this.system.viewport.fontSize): string => `${rem * base}${symbol}`;

    /**
     * Atajo para llamar pxToRem y luego remToPx, que obtiene el valor en pixeles calculado de acuerdo a la resolución del dispositivo
     * @see pxToRem
     * @see remToPx
     * @param px Valor en pixeles para pxToRem
     * @param options Objeto con las opciones para pxToRem y remToPx
     * @example {
     *  pxToRem: {
     *    symbol: 'rem',
     *    base: 15
     *  },
     *  remToPx: {
     *    symbol: 'px',
     *    base: this.system.viewport.fontSize
     *  }
     * }
     */
    getRemToPx = (px: number, options?): number => {
        const bases: [number, number] = [15, this.system.viewport.fontSize];
        const symbols: [('rem' | ''), ('px' | '')] = ['', ''];
        if (options) {
            if (options.pxToRem) {
                symbols[0] = ![null, undefined].includes(options.pxToRem.symbol) ? options.pxToRem.symbol : '';
                bases[0] = ![null, undefined].includes(options.pxToRem.base) ? options.pxToRem.base : 15;
            }
            if (options.remToPx) {
                bases[1] = ![null, undefined].includes(options.remToPx.base) ? options.remToPx.base : this.system.viewport.fontSize;
                symbols[1] = ![null, undefined].includes(options.remToPx.symbol) ? options.remToPx.symbol : '';
            }
        }
        return Math.floor(+this.remToPx(+this.pxToRem(px, symbols[0], bases[0]), symbols[1], bases[1]));
    }

    /**
     * Atajo para llamar pxToRem y luego remToPx, que obtiene el valor en pixeles calculado de acuerdo a la resolución del dispositivo sin redondeo
     * @see pxToRem
     * @see remToPx
     * @param px Valor en pixeles para pxToRem
     * @param options Objeto con las opciones para pxToRem y remToPx
     * @example {
     *  pxToRem: {
     *    symbol: 'rem',
     *    base: 15
     *  },
     *  remToPx: {
     *    symbol: 'px',
     *    base: this.system.viewport.fontSize
     *  }
     * }
     */
    getFullRemToPx = (px: number, options?): number => {
        const bases: [number, number] = [15, this.system.viewport.fontSize];
        const symbols: [('rem' | ''), ('px' | '')] = ['', ''];
        if (options) {
            if (options.pxToRem) {
                symbols[0] = ![null, undefined].includes(options.pxToRem.symbol) ? options.pxToRem.symbol : '';
                bases[0] = ![null, undefined].includes(options.pxToRem.base) ? options.pxToRem.base : 15;
            }
            if (options.remToPx) {
                bases[1] = ![null, undefined].includes(options.remToPx.base) ? options.remToPx.base : this.system.viewport.fontSize;
                symbols[1] = ![null, undefined].includes(options.remToPx.symbol) ? options.remToPx.symbol : '';
            }
        }
        return +this.remToPx(+this.pxToRem(px, symbols[0], bases[0]), symbols[1], bases[1]);
    }

    /**
     * Reordena un array aleatoriamente
     * @param arg Array a ordenar
     */
    randomizeArray(arg: any[]): any[] {
        const array = arg.slice();
        let currentIndex = array.length;
        let temporaryValue;
        let randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    /** Sufijos para números grandes */
    getNumberSuffix = (): any[] => [
        // { number: 1e+3, suffix: 'm', name: 'miles', format: { minimumFractionDigits: 0, maximumFractionDigits: 1 } },
        { number: 1e+6, suffix: 'M', name: 'millones', format: { minimumFractionDigits: 0, maximumFractionDigits: 2 } },
        { number: 1e+9, suffix: 'MM', name: 'miles de millones', format: { minimumFractionDigits: 3, maximumFractionDigits: 3 } },
        { number: 1e+12, suffix: 'B', name: 'billones', format: { minimumFractionDigits: 0, maximumFractionDigits: 2 } },
        { number: 1e+15, suffix: 'MB', name: 'miles de billones', format: { minimumFractionDigits: 3, maximumFractionDigits: 3 } },
        { number: 1e+18, suffix: 'T', name: 'trillones', format: { minimumFractionDigits: 0, maximumFractionDigits: 2 } },
        { number: 1e+21, suffix: 'MT', name: 'miles de trillones', format: { minimumFractionDigits: 3, maximumFractionDigits: 3 } },
        { number: 1e+24, suffix: 'C', name: 'cuatrillones', format: { minimumFractionDigits: 0, maximumFractionDigits: 2 } }
    ]

    /**
     * Reordena los índices un array aleatoriamente
     * @param arg Array a ordenar
     */
    randomizeIndex = (arg: any[]): number[] => this.randomizeArray(Array.from({ length: arg.length }, (u, i) => i));

    /**
     * Nombres de meses
     * @param index Número del mes
     * @param fromZero Inicia desde 0
     */
    getMonthName = (index: number, fromZero = true): string => this.months[fromZero ? index : (+index - 1)];

    /**
     * retorna el id de un mes
     * @param month Nombre del mes
     * @returns retorna id
     */
    getMonthId = (month: string): number => this.months.findIndex(e => e.toLowerCase() === month.toLowerCase());

    /**
     * Mínimo valor de un array
     * @param arg Array
     * @param prop propiedad si los valores son objetos
     */
    getMinValue = (arg: any[], prop?: string) => arg.reduce((pre, cur) => (prop ? cur[prop] : cur) > pre ? pre : (prop ? cur[prop] : cur), Infinity);

    /**
     * Máximo valor de un array
     * @param arg Array
     * @param prop propiedad si los valores son objetos
     */
    getMaxValue = (arg: any[], prop?: string) => arg.reduce((pre, cur) => (prop ? cur[prop] : cur) < pre ? pre : (prop ? cur[prop] : cur), -Infinity);

    /**
     * Verifica si una imagen carga correctamente
     * @param path Ubicación de la imagen
     */
    checkImg = (path: string): Promise<string> => new Promise(res => { const img = new Image(); img.onload = () => res(path); img.onerror = () => res(''); img.src = path; });

    /**
     * Obtiene el atributo ng del componente
     * @param elementRef Elemento de referencia
     */
    getComponentAttribute = (elementRef: HTMLElement): Attr[] => Array.from(elementRef.attributes).filter((elm: Attr) => elm.name.includes('_ng'));

    /**
     * Por documentar
     * @param data
     * @param max
     */
    separateArray = (data: any[], max = 5): any[][] => {
        if (data.length > 0) {
            const size = Math.floor(data.length / max) + (data.length % max === 0 ? 0 : 1);
            const times = Math.ceil(data.length / size);
            const result = Array.from({ length: size }, () => []);
            let [idx, time] = [0, 0];
            data.forEach((elm, i) => {
                time < times ? time++ : (time = 1, idx++);
                result[idx].push(elm);
            });
            return result;
        } else { return [[]]; }
    }

    getFormat(...styles: string[]): object {
        const formats = {
            currency: { style: 'currency', currency: 'COP', currencyDisplay: 'symbol' },
            decimal: { maximumFractionDigits: 2, minimumFractionDigits: 0 },
            signDisplay: { signDisplay: 'exceptZero' },
            percent: { style: 'percent' },
        };
        return styles.reduce((p: object, c: string) => ({ ...p, ...formats[c] }), {});
    }

    getSuffix(value: number): [number, string] {
        const suffixes = this.getNumberSuffix().sort((a, b) => +b.number - +a.number);
        let suffix: [number, string] = [1, ''];
        for (let i = 0; i < suffixes.length; i++) {
            if (+value >= suffixes[i].number || +value <= (suffixes[i].number * -1)) {
                suffix = [suffixes[i].number, suffixes[i].suffix]; i = suffixes.length;
            }
        }
        return suffix;
    }

    getSuffixObject(value: number): any {
        const suffixes = this.getNumberSuffix().sort((a, b) => +b.number - +a.number);
        let suffix: any = { number: 1, suffix: '', name: '', format: { minimumFractionDigits: 0, maximumFractionDigits: 0 } };
        for (let i = 0; i < suffixes.length; i++) {
            if (+value >= suffixes[i].number || +value <= (suffixes[i].number * -1)) {
                suffix = suffixes[i]; i = suffixes.length;
            }
        }
        return suffix;
    }

    // getObjectNotReferenced(): Método que devuelve un objeto nuevo sin referencias internas
    getObjectNotReferenced(object: object): object {
        // setObjectProperties(): Rompe la referencia de las propiedades de un objeto
        const setObjectProperties = (elmReferenced: object) => {
            // ↓ Objeto a retonar sin referencias
            const option = {} as any;
            // ↓ Itera las propiedades del objeto y rompe las referencias que pueda tener
            Object.keys(elmReferenced).forEach((elm: string) => {
                option[elm] = typeof elmReferenced[elm] !== 'object' ? (Object.values({ bk: elmReferenced[elm] })[0]) : (Array.isArray(elmReferenced[elm]) ? ([...elmReferenced[elm]]) : (elmReferenced[elm] === null ? null : { ...setObjectProperties(elmReferenced[elm]) }));
            });
            // ↓ Dvuelve el objeto sin referencias
            return option;
        };
        // objectToReturn: Objeto a retornar sin referencias
        const objectToReturn: object = {} as object;
        // ↓ Itera las propiedades del objeto y rompe las referencias que pueda tener
        Object.keys(object).forEach((elm: string) => {
            objectToReturn[elm] = typeof object[elm] !== 'object' ? (Object.values({ bk: object[elm] })[0]) : (Array.isArray(object[elm]) ? ([...object[elm]]) : (object[elm] === null ? null : { ...setObjectProperties(object[elm]) }));
        });
        // ↓ Devuelve el objeto sin referencias
        return { ...objectToReturn };
    }

    // Método que devuelve random de colores
    getRandomColor() {
        const caracteres = '123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += caracteres[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    /**
     * Elimina los caracteres especiales de texto
     * @param value Texto a evaluar
     * @returns Texto sin caracteres especiales
     */
    normalizeText = (value: string): string => (value || '').toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1');

    /**
     * Obtiene la variación de 2 valores
     * @param pv Valor inicial
     * @param cv Valor final
     * @returns Valor de la variación
     */
    setVariation(pv: number, cv: number, base: 1 | 100 = 1): number {
        if (pv === undefined || cv === undefined) { return undefined; }
        if (pv === cv) { return 0; }
        if (pv === 0) { return (cv > 0 ? 1 : -1) * base; }
        if (cv === 0) { return (pv > 0 ? -1 : 1) * base; }
        if (pv > 0 && cv > 0) { return ((cv / pv) - 1) * base; }
        const log = Math.log(Math.abs(pv) / Math.abs(cv));
        return (log + ((1 / 2) * Math.pow(log, 2)) - ((1 / 3) * Math.pow(log, 3)) + ((1 / 4) * Math.pow(log, 4))) * base;
    }
    /**
     * Obtiene la participación porcentual de un valor
     * @param value Valor
     * @param total Total
     * @returns Paticipación del valor
     */
    setParticipation(value: number, total: number, base: 1 | 100 = 1): number {
        if (value === undefined || total === undefined) { return undefined; }
        if (total === 0) { return base; }
        return (value / total) * base;
    }

    /**
     * Detecta cuando elementos DOM han sido creados o eliminados
     * @param target Elemento a revisar
     * @param callback Acción a realizar cuando ocurra un cambio
     * @param checkSubTree Si es verdadero, se observarán los hijos del elemento
     * @returns Referencia del observador
     */
    mutationObserver(target: Element | string, callback: (mutation: MutationRecord) => void, checkSubTree: boolean = false): MutationObserver {
        const observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                mutation.type === 'childList' && callback(mutation);
            }
        });
        // this.events.destroy.subscribe(() => observer.disconnect());
        observer.observe(target instanceof Element ? target : document.querySelector(target), { attributes: false, childList: true, subtree: checkSubTree });
        return observer;
    }

    /**
     * Elimina las comillas escapadas de un texto
     * @param value Texto
     * @returns Texto sin comillas escapadas
     */
    unquote = (value: string): string => value.replace(/(^['"])|(['"]$)/g, '').replace(/open-quote|close-quote/g, '"');

    /**
     * Adjunta el contenido css de los pseudo elementos ::after, ::before al texto del elemento
     * @param target Elemento a modificar
     */
    attachCssContent(target: HTMLElement) {
        let afterContent = window.getComputedStyle(target, ':after')?.getPropertyValue('content');
        let beforeContent = window.getComputedStyle(target, ':before').getPropertyValue('content');
        beforeContent === 'none' && (beforeContent = '');
        afterContent === 'none' && (afterContent = '');

        target.innerText = this.unquote(beforeContent) + target.innerText + this.unquote(afterContent);
    }

}
